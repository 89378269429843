import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useAuthStore } from '../stores/authStore'
import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

import RouteViewComponent from '../layouts/RouterBypass.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: {
          title: 'Dashboard - Assessment Kewirausahaan',
          metaTags: [
            {
              name: 'description',
              content: 'Halaman Dashboard dari aplikasi Assessment Kewirausahaan.',
            },
            {
              property: 'og:description',
              content: 'Halaman Dashboard dari aplikasi Assessment Kewirausahaan.',
            },
          ],
        },
      },
      {
        name: 'mahasiswa',
        path: 'mahasiswa',
        component: () => import('../pages/mahasiswa/MahasiswaPage.vue'),
        meta: {
          title: 'Mahasiswa - Assessment Kewirausahaan',
          metaTags: [
            {
              name: 'description',
              content: 'Halaman Mahasiswa dari aplikasi Assessment Kewirausahaan.',
            },
            {
              property: 'og:description',
              content: 'Halaman Mahasiswa dari aplikasi Assessment Kewirausahaan.',
            },
          ],
        },
      },
      {
        name: 'kelompok',
        path: 'kelompok',
        component: () => import('../pages/kelompok/KelompokPage.vue'),
        meta: {
          title: 'Kelompok - Assessment Kewirausahaan',
          metaTags: [
            {
              name: 'description',
              content: 'Halaman Kelompok dari aplikasi Assessment Kewirausahaan.',
            },
            {
              property: 'og:description',
              content: 'Halaman Kelompok dari aplikasi Assessment Kewirausahaan.',
            },
          ],
        },
      },
      {
        name: 'penilaian',
        path: '/penilaian',
        component: RouteViewComponent,
        children: [
          {
            name: 'observasi-wirausaha',
            path: 'observasi-wirausaha',
            component: () => import('../pages/penilaian/ObservasiWirausaha.vue'),
            meta: {
              title: 'Penilaian Observasi Wirausaha - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Penilaian Observasi Wirausaha dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Penilaian Observasi Wirausaha dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
          {
            name: 'business-plan',
            path: 'business-plan',
            component: () => import('../pages/penilaian/BusinessPlan.vue'),
            meta: {
              title: 'Penilaian Business Plan - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Penilaian Business Plan dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Penilaian Business Plan dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
          {
            name: 'riset-pasar',
            path: 'riset-pasar',
            component: () => import('../pages/penilaian/RisetPasar.vue'),
            meta: {
              title: 'Penilaian Riset Pasar - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Penilaian Riset Pasar dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Penilaian Riset Pasar dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
          {
            name: 'penilaian-produk',
            path: 'penilaian-produk',
            component: () => import('../pages/penilaian/PenilaianProduk.vue'),
            meta: {
              title: 'Penilaian Produk - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Penilaian Produk dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Penilaian Produk dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen', 'mahasiswa'],
            },
          },
          {
            name: 'e-commerce',
            path: 'e-commerce',
            component: () => import('../pages/penilaian/ECommerce.vue'),
            meta: {
              title: 'Penilaian E-Commerce - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Penilaian E-Commerce dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Penilaian E-Commerce dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen', 'mahasiswa'],
            },
          },
          {
            name: 'riwayat',
            path: 'riwayat',
            component: () => import('../pages/penilaian/RiwayatRekapan.vue'),
            meta: {
              title: 'Riwayat Penilaian - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Riwayat Penilaian dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Riwayat Penilaian dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
        ],
      },
      {
        name: 'rekapan',
        path: '/rekapan',
        component: RouteViewComponent,
        children: [
          {
            name: 'rekap-judul',
            path: 'rekap-judul',
            component: () => import('../pages/rekapan/RekapKelompok.vue'),
            meta: {
              title: 'Rekapan Judul - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Rekapan Judul dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Rekapan Judul dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
          {
            name: 'kumulatif-dosen',
            path: 'kumulatif-dosen',
            component: () => import('../pages/rekapan/KumulatifDosen.vue'),
            meta: {
              title: 'Rekapan Dosen - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Rekapan Dosen dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Rekapan Dosen dari aplikasi Assessment Kewirausahaan.',
                },
              ],
              requiresAuth: true,
              allowedRoles: ['dosen'],
            },
          },
          {
            name: 'kumulatif',
            path: 'kumulatif',
            component: () => import('../pages/rekapan/KumulatifKelompok.vue'),
            meta: {
              title: 'Rekapan Semua Nilai - Assessment Kewirausahaan',
              metaTags: [
                {
                  name: 'description',
                  content: 'Halaman Rekapan Semua Nilai dari aplikasi Assessment Kewirausahaan.',
                },
                {
                  property: 'og:description',
                  content: 'Halaman Rekapan Semua Nilai dari aplikasi Assessment Kewirausahaan.',
                },
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/LoginPage.vue'),
        meta: {
          title: 'Login - Assessment Kewirausahaan',
          metaTags: [
            {
              name: 'description',
              content: 'Halaman Login dari aplikasi Assessment Kewirausahaan.',
            },
            {
              property: 'og:description',
              content: 'Halaman Login dari aplikasi Assessment Kewirausahaan.',
            },
          ],
        },
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
    meta: {
      title: 'Dashboard - Assessment Kewirausahaan',
      metaTags: [
        {
          name: 'description',
          content: 'Halaman Dashboard dari aplikasi Assessment Kewirausahaan.',
        },
        {
          property: 'og:description',
          content: 'Halaman Dashboard dari aplikasi Assessment Kewirausahaan.',
        },
      ],
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

router.beforeEach((to, from, next) => {
  document.title = (to.meta.title as string) || 'Assessment Kewirausahaann'
  const authStore = useAuthStore()
  const isAuthenticated = authStore.isAuthenticated
  const userRole = authStore.userData.role

  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: 'login' })
  } else if (to.meta.requiresAuth && to.meta.roles && !to.meta.roles.includes(userRole)) {
    // Redirect or show an error if user role is not authorized
    next({ name: 'dashboard' }) // Ensure there's an 'unauthorized' route defined
    document.title = 'Dashboard - Assessment Kewirausahaan'
  } else {
    next()
  }
})
export default router
