<template>
  <svg
    version="1.0"
    alt="Vuestic Admin"
    xmlns="http://www.w3.org/2000/svg"
    :height="height"
    viewBox="0 0 6750.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)" fill="url(#paint0_linear)" stroke="none">
      <path
        d="M7095 4793 c-27 -2 -104 -10 -170 -19 -609 -76 -1054 -345 -1255
-758 -135 -277 -156 -621 -57 -911 113 -330 396 -561 882 -723 137 -46 260
-78 775 -202 327 -79 503 -150 610 -247 92 -84 128 -185 108 -308 -39 -245
-330 -385 -802 -385 -451 0 -955 148 -1316 386 -39 25 -73 45 -75 42 -7 -6
-325 -712 -325 -720 0 -4 33 -29 73 -56 410 -278 1104 -449 1732 -429 591 19
1046 181 1345 477 167 166 266 351 322 605 21 99 16 371 -10 476 -48 195 -116
321 -245 456 -97 101 -220 186 -380 263 -216 104 -356 145 -1083 319 -335 80
-506 151 -610 255 -73 74 -97 132 -96 240 4 292 295 465 785 466 356 0 740
-98 1084 -279 92 -49 94 -50 102 -28 5 12 70 172 145 356 75 183 137 340 138
348 6 29 -229 147 -434 218 -359 126 -836 186 -1243 158z"
      />
      <path
        d="M10925 4793 c-27 -2 -104 -10 -170 -19 -609 -76 -1054 -345 -1255
-758 -135 -277 -156 -621 -57 -911 113 -330 396 -561 882 -723 137 -46 260
-78 775 -202 327 -79 503 -150 610 -247 92 -84 128 -185 108 -308 -39 -245
-330 -385 -802 -385 -451 0 -955 148 -1316 386 -39 25 -73 45 -75 42 -7 -6
-325 -712 -325 -720 0 -4 33 -29 73 -56 410 -278 1104 -449 1732 -429 591 19
1046 181 1345 477 167 166 266 351 322 605 21 99 16 371 -10 476 -48 195 -116
321 -245 456 -97 101 -220 186 -380 263 -216 104 -356 145 -1083 319 -335 80
-506 151 -610 255 -73 74 -97 132 -96 240 4 292 295 465 785 466 356 0 740
-98 1084 -279 92 -49 94 -50 102 -28 5 12 70 172 145 356 75 183 137 340 138
348 6 29 -229 147 -434 218 -359 126 -836 186 -1243 158z"
      />
      <path
        d="M18775 4793 c-27 -2 -104 -10 -170 -19 -609 -76 -1054 -345 -1255
-758 -135 -277 -156 -621 -57 -911 113 -330 396 -561 882 -723 137 -46 260
-78 775 -202 327 -79 503 -150 610 -247 92 -84 128 -185 108 -308 -39 -245
-330 -385 -802 -385 -451 0 -955 148 -1316 386 -39 25 -73 45 -75 42 -7 -6
-325 -712 -325 -720 0 -4 33 -29 73 -56 410 -278 1104 -449 1732 -429 591 19
1046 181 1345 477 167 166 266 351 322 605 21 99 16 371 -10 476 -48 195 -116
321 -245 456 -97 101 -220 186 -380 263 -216 104 -356 145 -1083 319 -335 80
-506 151 -610 255 -73 74 -97 132 -96 240 4 292 295 465 785 466 356 0 740
-98 1084 -279 92 -49 94 -50 102 -28 5 12 70 172 145 356 75 183 137 340 138
348 6 29 -229 147 -434 218 -359 126 -836 186 -1243 158z"
      />
      <path
        d="M22605 4793 c-27 -2 -104 -10 -170 -19 -609 -76 -1054 -345 -1255
-758 -135 -277 -156 -621 -57 -911 113 -330 396 -561 882 -723 137 -46 260
-78 775 -202 327 -79 503 -150 610 -247 92 -84 128 -185 108 -308 -39 -245
-330 -385 -802 -385 -451 0 -955 148 -1316 386 -39 25 -73 45 -75 42 -7 -6
-325 -712 -325 -720 0 -4 33 -29 73 -56 410 -278 1104 -449 1732 -429 591 19
1046 181 1345 477 167 166 266 351 322 605 21 99 16 371 -10 476 -48 195 -116
321 -245 456 -97 101 -220 186 -380 263 -216 104 -356 145 -1083 319 -335 80
-506 151 -610 255 -73 74 -97 132 -96 240 4 292 295 465 785 466 356 0 740
-98 1084 -279 92 -49 94 -50 102 -28 5 12 70 172 145 356 75 183 137 340 138
348 6 29 -229 147 -434 218 -359 126 -836 186 -1243 158z"
      />
      <path
        d="M56205 4746 c-299 -49 -484 -207 -518 -442 -28 -194 56 -360 229
-451 80 -42 152 -65 377 -122 105 -26 215 -58 243 -70 135 -58 164 -180 64
-265 -52 -43 -129 -61 -270 -60 -181 1 -338 43 -483 129 -32 19 -62 35 -66 35
-4 0 -35 -64 -70 -142 l-63 -143 24 -18 c106 -86 373 -163 603 -174 350 -16
623 103 725 318 82 174 53 376 -71 498 -90 89 -212 139 -513 211 -269 64 -346
110 -346 208 0 29 8 59 20 79 84 138 409 149 679 22 46 -21 85 -38 87 -37 5 7
114 278 114 286 0 23 -193 99 -325 127 -98 22 -340 28 -440 11z"
      />
      <path
        d="M2521 4703 c-10 -21 -1694 -3798 -1837 -4121 l-24 -52 498 0 498 0
185 447 184 448 977 3 976 2 26 -62 c14 -35 98 -236 185 -448 l160 -385 510
-3 511 -2 -11 22 c-5 13 -427 957 -937 2098 l-928 2075 -480 3 -481 2 -12 -27z
m820 -1735 c180 -436 327 -794 325 -795 -1 -2 -300 -2 -663 -1 l-660 3 330
798 c182 438 332 795 335 792 3 -2 152 -361 333 -797z"
      />
      <path
        d="M13460 2630 l0 -2100 1625 0 1625 0 0 390 0 390 -1145 0 -1145 0 0
485 0 485 975 0 975 0 0 375 0 375 -972 2 -973 3 -3 458 -2 457 1105 0 1105 0
0 390 0 390 -1585 0 -1585 0 0 -2100z"
      />
      <path
        d="M25140 2630 l0 -2100 455 0 455 0 2 1229 3 1229 614 -1009 615 -1009
217 0 217 0 612 1027 c336 566 616 1032 621 1037 5 6 9 -499 9 -1247 l0 -1257
458 0 457 0 -2 1627 c-1 896 -5 1841 -9 2101 l-6 472 -397 0 -396 0 -770
-1297 c-423 -713 -774 -1297 -779 -1297 -5 -1 -361 582 -790 1294 l-781 1295
-403 3 -402 2 0 -2100z"
      />
      <path
        d="M30870 2630 l0 -2100 1625 0 1625 0 0 390 0 390 -1145 0 -1145 0 0
485 0 485 975 0 975 0 0 375 0 375 -972 2 -973 3 -3 458 -2 457 1105 0 1105 0
0 390 0 390 -1585 0 -1585 0 0 -2100z"
      />
      <path
        d="M34890 2630 l0 -2100 480 0 480 0 2 1272 3 1272 1044 -1272 1043
-1272 399 0 399 0 0 2100 0 2100 -480 0 -480 0 -2 -1272 -3 -1271 -1040 1271
-1040 1272 -402 0 -403 0 0 -2100z"
      />
      <path
        d="M39260 4335 l0 -395 675 0 675 0 0 -1705 0 -1705 485 0 485 0 0 1705
0 1705 670 0 670 0 0 395 0 395 -1830 0 -1830 0 0 -395z"
      />
      <path
        d="M43420 3890 l0 -840 195 0 195 0 0 214 0 214 113 116 112 116 260
-321 c143 -176 265 -325 271 -330 7 -5 105 -8 233 -7 l221 3 -365 460 c-200
253 -363 463 -362 467 1 4 131 146 288 315 157 169 312 336 343 371 l58 62
-215 0 -215 0 -369 -388 -368 -388 -3 388 -2 388 -195 0 -195 0 0 -840z"
      />
      <path
        d="M45200 3890 l0 -840 650 0 650 0 0 155 0 155 -455 0 -455 0 0 195 0
195 390 0 390 0 0 150 0 150 -390 0 -390 0 0 185 0 185 440 0 440 0 0 155 0
155 -635 0 -635 0 0 -840z"
      />
      <path
        d="M46640 4723 c0 -5 123 -381 273 -838 l273 -830 209 0 208 0 183 547
c100 301 186 554 191 563 7 11 62 -145 193 -550 l184 -565 207 0 208 0 165
503 c90 276 214 653 274 837 l110 335 -181 3 c-99 1 -184 -1 -187 -5 -4 -5
-92 -267 -195 -583 -103 -316 -191 -579 -195 -583 -3 -4 -30 66 -59 155 -29
90 -115 354 -190 588 l-137 425 -180 3 -179 2 -189 -562 c-219 -651 -205 -612
-210 -607 -3 3 -290 892 -367 1137 l-11 32 -199 0 c-109 0 -199 -3 -199 -7z"
      />
      <path d="M49540 3890 l0 -840 195 0 195 0 0 840 0 840 -195 0 -195 0 0 -840z" />
      <path
        d="M50320 3890 l0 -840 195 0 195 0 0 235 0 235 178 0 178 0 159 -235
160 -234 208 -1 c164 0 207 3 201 13 -4 6 -88 128 -187 270 -98 142 -177 259
-175 260 1 1 25 13 52 27 124 63 237 210 271 351 32 138 10 325 -52 442 -35
65 -142 174 -208 211 -71 39 -207 83 -298 95 -45 6 -250 11 -478 11 l-399 0 0
-840z m895 492 c99 -45 154 -123 163 -228 9 -121 -35 -218 -124 -270 -74 -44
-139 -54 -351 -54 l-193 0 0 291 0 291 228 -4 c212 -3 230 -5 277 -26z"
      />
      <path
        d="M52587 4658 c-17 -40 -185 -417 -373 -838 l-341 -765 195 -3 c107 -1
197 -1 199 2 2 2 37 82 76 177 l72 174 392 3 391 2 34 -82 c19 -46 52 -127 74
-180 l39 -98 203 0 c153 0 203 3 200 12 -2 6 -170 384 -374 840 l-369 827
-193 1 -192 0 -33 -72z m358 -637 c69 -167 125 -307 125 -312 0 -5 -114 -9
-260 -9 -196 0 -260 3 -260 12 0 24 255 627 262 620 4 -4 64 -144 133 -311z"
      />
      <path
        d="M53873 4178 c3 -534 4 -556 25 -638 34 -127 83 -220 158 -300 77 -81
137 -122 238 -161 161 -62 403 -75 581 -29 104 26 226 87 292 145 104 91 183
244 212 409 7 37 11 265 11 593 l0 533 -190 0 -190 0 0 -502 c0 -293 -4 -530
-10 -568 -14 -89 -60 -180 -116 -229 -66 -58 -127 -76 -254 -76 -94 0 -111 3
-168 29 -105 48 -170 142 -192 278 -6 37 -10 272 -10 566 l0 502 -196 0 -195
0 4 -552z"
      />
      <path
        d="M57807 4658 c-17 -40 -185 -417 -373 -838 l-341 -765 195 -3 c107 -1
197 -1 199 2 2 2 37 82 76 177 l72 174 392 3 391 2 34 -82 c19 -46 52 -127 74
-180 l39 -98 203 0 c153 0 203 3 200 12 -2 6 -170 384 -374 840 l-369 827
-193 1 -192 0 -33 -72z m358 -637 c69 -167 125 -307 125 -312 0 -5 -114 -9
-260 -9 -196 0 -260 3 -260 12 0 24 255 627 262 620 4 -4 64 -144 133 -311z"
      />
      <path
        d="M59150 3890 l0 -840 195 0 195 0 0 345 0 345 380 0 380 0 0 -345 0
-345 195 0 195 0 0 840 0 840 -195 0 -195 0 0 -330 0 -330 -380 0 -380 0 0
330 0 330 -195 0 -195 0 0 -840z"
      />
      <path
        d="M61587 4658 c-17 -40 -185 -417 -373 -838 l-341 -765 195 -3 c107 -1
197 -1 199 2 2 2 37 82 76 177 l72 174 392 3 391 2 34 -82 c19 -46 52 -127 74
-180 l39 -98 203 0 c153 0 203 3 200 12 -2 6 -170 384 -374 840 l-369 827
-193 1 -192 0 -33 -72z m358 -637 c69 -167 125 -307 125 -312 0 -5 -114 -9
-260 -9 -196 0 -260 3 -260 12 0 24 255 627 262 620 4 -4 64 -144 133 -311z"
      />
      <path
        d="M63477 4658 c-17 -40 -185 -417 -373 -838 l-341 -765 195 -3 c107 -1
197 -1 199 2 2 2 37 82 76 177 l72 174 392 3 391 2 34 -82 c19 -46 52 -127 74
-180 l39 -98 203 0 c153 0 203 3 200 12 -2 6 -170 384 -374 840 l-369 827
-193 1 -192 0 -33 -72z m358 -637 c69 -167 125 -307 125 -312 0 -5 -114 -9
-260 -9 -196 0 -260 3 -260 12 0 24 255 627 262 620 4 -4 64 -144 133 -311z"
      />
      <path
        d="M64820 3890 l0 -840 190 0 190 0 0 506 c0 281 4 504 9 502 4 -1 193
-228 418 -503 l409 -500 162 -3 162 -2 0 840 0 840 -190 0 -190 0 0 -505 c0
-278 -3 -505 -7 -505 -5 1 -192 228 -418 505 l-410 504 -162 1 -163 0 0 -840z"
      />
    </g>
    <defs>
      <linearGradient id="paint0_linear" gradientUnits="userSpaceOnUse" x1="0" x2="357.64" y1="56.1386" y2="56.1386">
        <stop :stop-color="colorsComputed.end" />
        <stop :stop-color="colorsComputed.start" offset="1" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { useColors } from 'vuestic-ui'

const { getColor } = useColors()

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
})
</script>
