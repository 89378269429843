export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'Home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'mahasiswa',
      displayName: 'Mahasiswa',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'kelompok',
      displayName: 'Kelompok',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'penilaian',
      displayName: 'Penilaian',
      meta: {
        icon: 'assessment',
      },
      children: [
        {
          name: 'penilaian-produk',
          displayName: 'Penilaian produk',
        },
        {
          name: 'riwayat',
          displayName: 'Riwayat',
        },
      ],
    },
    {
      name: 'rekapan',
      displayName: 'Rekapan',
      meta: {
        icon: 'summarize',
      },
      children: [
        {
          name: 'kumulatif',
          displayName: 'Kumulatif',
        },
      ],
    },
  ] as INavigationRoute[],
}
