<template>
  <div class="app-navbar-actions">
    <VaButton
      v-if="!isMobile && !isAuthenticated"
      preset="secondary"
      to="/auth/login"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
    >
      Login
    </VaButton>
    <VaButton
      v-if="!isMobile && isAuthenticated"
      preset="secondary"
      to="/auth/login"
      color="textPrimary"
      class="app-navbar-actions__item flex-shrink-0 mx-0"
      @click="logout"
    >
      Logout
    </VaButton>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, computed } from 'vue'
import { useAuthStore } from '../../../stores/authStore'
defineProps({
  isMobile: { type: Boolean, default: false },
})

const authStore = useAuthStore()
authStore.checkAuth()
const isAuthenticated = computed(() => !!authStore.userData.user)
const logout = () => {
  authStore.logoutUser()
}
</script>

<style lang="scss">
.app-navbar-actions {
  display: flex;
  align-items: center;

  .va-dropdown__anchor {
    color: var(--va-primary);
    fill: var(--va-primary);
  }

  &__item {
    padding: 0;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    svg {
      height: 20px;
    }

    &--profile {
      display: flex;
      justify-content: center;
    }

    .va-dropdown-content {
      background-color: var(--va-white);
    }

    @media screen and (max-width: 640px) {
      margin-left: 0;
      margin-right: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .fa-github {
    color: var(--va-on-background-primary);
  }
}
</style>
