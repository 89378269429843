<template>
  <VaSidebar v-model="writableVisible" :width="sidebarWidth" :color="color" minimized-width="0">
    <VaAccordion v-model="value" multiple>
      <VaCollapse v-for="(route, index) in navigationRoutes.routes" :key="index">
        <template #header="{ value: isCollapsed }">
          <VaSidebarItem
            :to="route.children ? undefined : { name: route.name }"
            :active="routeHasActiveChild(route)"
            :active-color="activeColor"
            :text-color="textColor(route)"
            :aria-label="`${route.children ? 'Open category ' : 'Visit'} ${route.displayName}`"
            role="button"
            hover-opacity="0.10"
          >
            <VaSidebarItemContent class="py-3 pr-2 pl-4">
              <VaIcon
                v-if="route.meta.icon"
                aria-hidden="true"
                :name="route.meta.icon"
                size="20px"
                :color="iconColor(route)"
              />
              <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
                {{ route.displayName }}
                <VaIcon v-if="route.children" :name="arrowDirection(isCollapsed)" size="20px" />
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
        </template>
        <template #body>
          <div v-for="(childRoute, index2) in route.children" :key="index2">
            <VaSidebarItem
              :to="{ name: childRoute.name }"
              :active="isActiveChildRoute(childRoute)"
              :active-color="activeColor"
              :text-color="textColor(childRoute)"
              :aria-label="`Visit ${route.displayName}`"
              hover-opacity="0.10"
            >
              <VaSidebarItemContent class="py-3 pr-2 pl-11">
                <VaSidebarItemTitle class="leading-5 font-semibold">
                  {{ childRoute.displayName }}
                </VaSidebarItemTitle>
              </VaSidebarItemContent>
            </VaSidebarItem>
          </div>
        </template>
      </VaCollapse>
      <VaSidebarItem
        v-if="props.mobile && !isAuthenticated"
        to="/auth/login"
        :active-color="activeColor"
        class="va-primary"
        aria-label="Visit Login"
        role="button"
        hover-opacity="0.10"
      >
        <VaSidebarItemContent class="py-3 pr-2 pl-4">
          <VaIcon aria-hidden="true" name="login" size="20px" />
          <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
            Login
          </VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
      <VaSidebarItem
        v-if="props.mobile && isAuthenticated"
        to="/auth/login"
        :active-color="activeColor"
        class="va-primary"
        aria-label="Logout"
        role="button"
        hover-opacity="0.10"
        @click="logout"
      >
        <VaSidebarItemContent class="py-3 pr-2 pl-4">
          <VaIcon aria-hidden="true" name="logout" size="20px" />
          <VaSidebarItemTitle class="flex justify-between items-center leading-5 font-semibold">
            Logout
          </VaSidebarItemTitle>
        </VaSidebarItemContent>
      </VaSidebarItem>
    </VaAccordion>
  </VaSidebar>
</template>

<script lang="ts" setup>
import { watch, ref, computed } from 'vue'
import { useRoute } from 'vue-router'

import { useColors } from 'vuestic-ui'
import { useAuthStore } from '../../stores/authStore' // Adjust the path to your authStore

import navigationRoutesTamu, { type INavigationRoute } from './NavigationRoutesTamu'
import navigationRoutesDosen from './NavigationRoutesDosen'
import navigationRoutesMahasiswa from './NavigationRoutesMahasiswa'

let navigationRoutes: any
const authStore = useAuthStore()
authStore.checkAuth()
const isAuthenticated = computed(() => !!authStore.userData.user)
const logout = () => {
  authStore.logoutUser()
}
if (authStore.userData && authStore.userData.user) {
  if (authStore.userData.user.role === 'dosen') {
    navigationRoutes = navigationRoutesDosen
  } else if (authStore.userData.user.role === 'mahasiswa') {
    navigationRoutes = navigationRoutesMahasiswa
  } else {
    navigationRoutes = navigationRoutesTamu // for any other roles
  }
} else {
  navigationRoutes = navigationRoutesTamu // for guest users
}

const props = defineProps({
  visible: { type: Boolean, default: true },
  mobile: { type: Boolean, default: false },
})

const emit = defineEmits(['update:visible'])

const { getColor, colorToRgba } = useColors()
const route = useRoute()
const value = ref<boolean[]>([])

const writableVisible = computed({
  get: () => props.visible,
  set: (v: boolean) => emit('update:visible', v),
})

const isActiveChildRoute = (child: INavigationRoute) => route.name === child.name

const routeHasActiveChild = (section: INavigationRoute) => {
  if (!section.children) {
    return route.path.endsWith(`${section.name}`)
  }

  return section.children.some(({ name }) => route.path.endsWith(`${name}`))
}

const setActiveExpand = () =>
  (value.value = navigationRoutes.routes.map((route: INavigationRoute) => routeHasActiveChild(route)))

const sidebarWidth = computed(() => (props.mobile ? '100vw' : '280px'))
const color = computed(() => getColor('background-secondary'))
const activeColor = computed(() => colorToRgba(getColor('focus'), 0.1))

const iconColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'secondary')
const textColor = (route: INavigationRoute) => (routeHasActiveChild(route) ? 'primary' : 'textPrimary')
const arrowDirection = (state: boolean) => (state ? 'va-arrow-up' : 'va-arrow-down')

watch(() => route.fullPath, setActiveExpand, { immediate: true })
</script>
