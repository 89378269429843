export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'Home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'Dashboard',
      meta: {
        icon: 'dashboard',
      },
    },
    {
      name: 'mahasiswa',
      displayName: 'Mahasiswa',
      meta: {
        icon: 'group',
      },
    },
    {
      name: 'kelompok',
      displayName: 'Kelompok',
      meta: {
        icon: 'folder_shared',
      },
    },
    {
      name: 'penilaian',
      displayName: 'Penilaian',
      meta: {
        icon: 'assessment',
      },
      children: [
        {
          name: 'observasi-wirausaha',
          displayName: 'Observasi wirausaha',
        },
        {
          name: 'business-plan',
          displayName: 'Business plan',
        },
        {
          name: 'riset-pasar',
          displayName: 'Riset pasar',
        },
        {
          name: 'penilaian-produk',
          displayName: 'Penilaian produk',
        },
        {
          name: 'e-commerce',
          displayName: 'E-Commerce',
        },
        {
          name: 'riwayat',
          displayName: 'Riwayat Penilaian',
        },
      ],
    },
    {
      name: 'rekapan',
      displayName: 'Rekapan',
      meta: {
        icon: 'summarize',
      },
      children: [
        {
          name: 'rekap-judul',
          displayName: 'Rekap Judul',
        },
        {
          name: 'kumulatif-dosen',
          displayName: 'Rekapan Dosen',
        },
        {
          name: 'kumulatif',
          displayName: 'Rekapan Nilai',
        },
      ],
    },
  ] as INavigationRoute[],
}
