import { defineStore } from 'pinia'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    isAuthenticated: false,
    userData: {} as any,
  }),
  actions: {
    loginUser(userData: any, remember: boolean) {
      this.userData = userData
      this.isAuthenticated = true
      const storage = remember ? localStorage : sessionStorage
      storage.setItem('auth', JSON.stringify(userData))
    },
    logoutUser() {
      this.userData = {}
      this.isAuthenticated = false
      localStorage.removeItem('auth')
      sessionStorage.removeItem('auth')
    },
    checkAuth() {
      const userData = localStorage.getItem('auth') || sessionStorage.getItem('auth')
      if (userData) {
        this.userData = JSON.parse(userData)
        this.isAuthenticated = true
      }
    },
  },
})
