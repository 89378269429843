<template>
  <VaSwitch
    v-model="isDarkTheme"
    color="#5123a1"
    off-color="#ffd300"
    style="--va-switch-checker-background-color: #252723"
  >
    <template #innerLabel>
      <div class="va-text-center">
        <VaIcon :name="isDarkTheme ? 'dark_mode' : 'light_mode'" />
      </div>
    </template>
  </VaSwitch>
</template>

<script lang="ts" setup>
import { computed, watch } from 'vue'
import { useColors } from 'vuestic-ui'

const THEME_KEY = 'user-theme'
const { applyPreset, currentPresetName } = useColors()

const isDarkTheme = computed({
  get() {
    return currentPresetName.value === 'dark'
  },
  set(value) {
    const theme = value ? 'dark' : 'light'
    applyPreset(theme)
    localStorage.setItem(THEME_KEY, theme)
  },
})

// Apply the saved theme initially
const savedTheme = localStorage.getItem(THEME_KEY) || currentPresetName.value
applyPreset(savedTheme)

watch(isDarkTheme, (newTheme) => {
  const theme = newTheme ? 'dark' : 'light'
  localStorage.setItem(THEME_KEY, theme)
})
</script>
